import * as Fathom from 'fathom-client'

export const initAnalytics = () => {
  console.log('Analytics Init')
  let fathomId = process.env.DEV ? 'EICMQDUH' : 'RIMFYEXP'
  Fathom.load(fathomId, { includedDomains: ['www.challengehound.com', 'beta.challengehound.com'] })
}

export const logPageView = () => {
  if (process.env.DEV) console.log(`Analytics: pageview for ${window.location.pathname}`)
  Fathom.trackPageview()
}

export const logEvent = (key, value) => {
  if (key) {
    const eventHash = {
      'purchase-credits': 'HI1V0NUW',
      'purchase-entryFee': 'QJIPGYBM',
      'purchase-map': 'IQFBILHN',
      'challenge-created': 'UMILZNG9',
      'challenge-joined': 'IJZLFNYA'
    }
    if (eventHash[key]) {
      if (process.env.DEV) console.log('Analytics Event', { key, value, id: eventHash[key] })
      Fathom.trackGoal(eventHash[key], parseInt(value, 10))
    } else {
      console.log('Event for tracking goals not found')
    }
  }
}
