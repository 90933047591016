import useSWR from 'swr'
import { jsonGetFetcher } from '../src/fetchers'
import localStorage from '../src/local-storage'

function useUser (route) {
  const serverSide = Boolean(typeof window === 'undefined')
  
  function isAnonymous (user) {
    const fakes = [
      'Unnamed',
      'Anonymous',
      'Obscure',
      'Nameless',
      'Untitled',
      'Incognito',
      'Unsung',
      'Unspecified',
      'Undisclosed',
      'Secret',
      'Unidentified',
      'Concealed',
      'Hushed',
      'Masked'
    ]
    if (user?.email) {
      return user?.email.match(/privaterelay.appleid.com/) && (fakes.includes(user?.fname) && user?.lname === 'Hound')
    }
    return false
  }

  const defaultHook = {
    loggedIn: false,
    loading: false,
    user: {},
    error: false,
    isAnonymous
  }

  const requireSignin = (loggedIn) => {
    if (loggedIn || serverSide) return
    localStorage.removeUser()
    localStorage.removeChallengeCache()
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ logout: true }))
    } else {
      window.location.href = '/signin' // hard redirect will clear swr user
    }
  }

  // entry sets localstorage user, so it is only instance where hook should exit
  let isEntry = false
  if (route === '/entry' || route === '/facebook') isEntry = true

  // localStorage required to make requests
  let user
  if (!serverSide) {
    user = localStorage.getUser()
  }
  
  let url = `${process.env.API}/users/me`

  // this can probably be removed after 1/1/24. meant to quick fill values
  if (user && user.id && !user.timezone) {
    const adminUser = localStorage.getAdminUser()
    if (!adminUser || !adminUser?.id) { // do NOT update if impersonating
      const tz = Intl?.DateTimeFormat().resolvedOptions().timeZone
      if (tz) url = `${url}?timezone=${tz}` // append
    }
  }

  const { data, error, mutate } = useSWR(!isEntry && !serverSide && user ? [url, user.token] : null, jsonGetFetcher, {
    revalidateOnMount: true,
    fallbackData: user,
    focusThrottleInterval: 60000,
    compare: (a, b) => {
      if (!a && !b) return false
      if (a && !b) return false
      if (!a && b) return false
      const accountData = (a.fname === b.fname && a.lname === b.lname && a.email === b.email && a.imperial === b.imperial)
      const orgs = (a.organization && b.organization) ? a.organization.balance === b.organization.balance && a.organization.name === b.organization.name && a.organization.stripeAccountId === b.organization.stripeAccountId && a.organization.stripeAccountEnabled === b.organization.stripeAccountEnabled : true
      const challenges = a.challengesJoined && b.challengesJoined && a.challengesJoined.length === b.challengesJoined.length
      const groups = a.groupsJoined && b.groupsJoined && a.groupsJoined.length === b.groupsJoined.length
      const photo  = a.photo === b.photo
      const metric = a.imperial === b.imperial
      const fullname = a.fullname === b.fullname
      const integrations = a.integrations && b.integrations && a.integrations.length === b.integrations.length
      const stravaUserId = a.stravaUserId === b.stravaUserId
      const appleHealth = a.appleHealth === b.appleHealth
      const meta = JSON.stringify(a.meta) === JSON.stringify(b.meta)
      const cookies = a.cookies === b.cookies
      if (accountData && orgs && challenges && groups && photo && metric && fullname && integrations && stravaUserId && meta && cookies && appleHealth) return true
      localStorage.setUser(b)
      return false
    }
  })

  if (!error && !data) {
    return { ...defaultHook, loggedIn: Boolean(user), loading: true, requireSignin } // assuming loggedIn=true while req is made
  }

  if (error) {
    if (error.status === 401 || error.status === 404) {
      return { ...defaultHook, loggedIn: false, loading: false, requireSignin }
    }
    if (user && user.id) {
      // return cache
      return { ...defaultHook, error: true, loading: false, loggedIn: true, user, requireSignin }
    }
    console.error(error)
    return { ...defaultHook, error: true, loading: false, requireSignin }
  }
  
  return { ...defaultHook, loggedIn: Boolean(user), loading: false, user: data, mutate, requireSignin }
}

export default useUser
