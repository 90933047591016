export const jsonGetFetcher = async (url, token) => {
  let options = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
  if (token) options.headers['Authorization'] = token
  
  const res = await fetch(url, options)
  
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.')
    try {
      error.info = await res.json() 
    } catch (err) {
      // do nothing, nothing to parse
    }
    error.status = res.status
    throw error
  }

  return await res.json()
}
