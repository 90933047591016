import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Router from 'next/router'
import { NextUIProvider } from "@nextui-org/react"

import localStorage from '../src/local-storage'
import { UserContext } from '../contexts/user-context'
import { HelpScout } from '../contexts/help-scout'
import useUser from '../hooks/use-user'

import { initAnalytics, logPageView } from '../src/analytics'


import '../public/empty.css'
import './global-styles.css'
import 'tailwindcss/tailwind.css'

import 'mapbox-gl/dist/mapbox-gl.css'

export default function App({ Component, pageProps, err }) {
  const [helpScoutLoading, setHelpScoutLoading] = useState(false)
  const [helpScoutLoaded, setHelpScoutLoaded] = useState(false)
  
  const router = useRouter()
  const userHook = useUser(router.route)
  const user = userHook.user

  useEffect(() => {
    window.nextRouter = Router
    window.appleHealth = false
    window.healthConnect = false
    initAnalytics()
    function onRouteChangeComplete() { logPageView() }
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router.events])

  const helpScout = {
    helpScoutLoading,
    setHelpScoutLoading,
    helpScoutLoaded,
    setHelpScoutLoaded,
    loadBeacon: (open = true, cb) => {
      const cc = localStorage.getCookieConsent()
      if (typeof cc === 'undefined' && !userHook.loggedIn && (!user.cookies || user.cookies === 'null')) {
        // send all users to helpscout, unless user is logged in and we have cookies enabled
        window.location.href = 'https://support.challengehound.com/';
        return
      }
      if (window.Beacon) return
      if (typeof window !== 'undefined' && window.location === window.parent.location) { // only on main site, not when embedded
        // Help Scout Beacon
        setHelpScoutLoading(true)
        !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
        window.Beacon('init', '8a5d44de-0bbd-4d5a-a7bd-3f6e0dba10d6')
        window.Beacon('config', {
          display: {
            zIndex: 49
          }
        })

        // Once loaded, hide the fake beacon and open the real one
        window.Beacon('once', 'ready', function() {
          helpScout.setHelpScoutLoading(false)
          helpScout.setHelpScoutLoaded(true)
        })
      }
      
      // whenever page is refreshed, set new user object in local storage
      if (userHook.loggedIn && user && user.id && window.Beacon) {
        let challengeCount
        let groupCount
        let groupsOwned
        if (user.challengesJoined && user.challengesJoined.length) {
          challengeCount = user.challengesJoined.length
        }
        if (user.groupsJoined && user.groupsJoined.length) {
          groupCount = user.groupsJoined.length
          if (user.organizationId) {
            const groups = user.groupsJoined.filter(g => g.organizationId === user.organizationId)
            if (groups.length) groupsOwned = groups.length
          }
        }
        window.Beacon('identify', {
          userId: user.id,
          name: user.fullname,
          email: user.email || undefined,
          avatar: user.photo || undefined,
          stravauserid: user.stravaUserId || undefined,
          facebookid: user.facebookId || undefined,
          organizationid: user.organizationId || undefined,
          challengecount: challengeCount,
          groupcount: groupCount,
          groupsowned: groupsOwned
        })
      }
      if (open) window.Beacon('open')
    },
    openBeacon: (id) => {
      helpScout.loadBeacon(false)
      window.Beacon('article', id)
    },
    openForm: () => {
      helpScout.loadBeacon(false)
      window.Beacon('open')
      window.Beacon('config', { mode: 'askFirst' })
      window.Beacon('navigate', '/ask/')
      window.Beacon('prefill', {
        userId: user.id,
        name: user.fullname,
        email: user.email || undefined,
        avatar: user.photo || undefined,
        subject: 'Add a Map'
      })
    }
  }
  
  return <NextUIProvider navigate={router.push}>
    <UserContext.Provider value={userHook}>
      <HelpScout.Provider value={helpScout}>
        <Component {...pageProps} err={err} />
      </HelpScout.Provider>
    </UserContext.Provider>
  </NextUIProvider>
}
